<template>
  <div class="w-100 p-4 h-100 overflow-auto">
    <b-container fluid="xl">
      <div class="d-flex w-100 justify-content-between align-items-center mb-3">
        <div class="h3">
          Не склеенные услуги
        </div>

        <b-form-select
          v-model="priceType"
          size="sm"
          value-field="id"
          text-field="title"
          :options="PRICE_TYPES"
          :disabled="isLoading"
          class="mr-2 w-25"
        >
          <b-form-select-option :value="null">
            Все типы
          </b-form-select-option>
        </b-form-select>
      </div>

      <template v-if="!isLoading">
        <b-table
          :fields="fields"
          :items="nonGluedServices"
          bordered
          hover
          small
          class="bg-white crm-table"
        >
          <template v-slot:cell(type)="row">
            {{ row.item.type != null ? PRICE_TYPES[row.item.type].title : '' }}
          </template>
        </b-table>

        <div class="d-flex mt-2">
          <b-form-select
            v-model="take"
            size="sm"
            :options="[10, 25, 50]"
            style="width: 70px; height: 35px"
            class="mr-2"
          />
          <b-pagination
            v-model="page"
            :total-rows="+allClinicsCount"
            :per-page="take"
          />
        </div>
      </template>
      <b-skeleton-table
        v-else
        :rows="take"
        :columns="fields.length"
        :table-props="{ bordered: true, striped: true, small: true, }"
      />
    </b-container>
  </div>
</template>

<script>
import { PRICE_TYPES } from '@/helpers/consts';
import { clinicService } from '@/services';
import { showErrorCustomMessage } from '@/helpers/messages';

export default {
  name: 'NonGluedServices',

  data: () => ({
    isLoading: false,
    nonGluedServices: [],
    fields: [
      {
        key: 'code',
        label: 'Код услуги',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'type',
        label: 'Тип прайса',
      },
      {
        key: 'clinicName',
        label: 'Название клиники',
      },
      {
        key: 'name',
        label: 'Название услуги',
      },
    ],
    PRICE_TYPES,
    page: 1,
    take: 25,
    allClinicsCount: 0,
    priceType: null,
  }),

  watch: {
    async page() {
      this.isLoading = true;

      try {
        await this.fetchClinics();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async take() {
      this.isLoading = true;

      try {
        this.page = 1;
        await this.fetchClinics();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async priceType() {
      this.isLoading = true;

      try {
        this.page = 1;
        await this.fetchClinics();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },

  async created() {
    this.isLoading = true;

    try {
      await this.fetchClinics();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async fetchClinics() {
      try {
        const { originalCount, paginatedCollection } = await clinicService.getPricesListForOne({
          isBind: false,
          take: this.take,
          skip: this.take * (this.page - 1),
          type: this.priceType,
          isActive: true,
        });
        this.nonGluedServices = paginatedCollection;
        this.allClinicsCount = originalCount;
      } catch (err) {
        showErrorCustomMessage('Не удалось загрузить список прайсов.');
        console.warn(err);
        throw err;
      }
    },
  },
};
</script>

<style scoped>
</style>
